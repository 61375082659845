import '../styles/index.scss';

console.log('webpack starterkit');

//////////////////////
// MODALS
/////////////////////

const modalTriggers = document.querySelectorAll('.popup-trigger');
const modalCloseTrigger = document.querySelector('.popup-modal__close');

modalTriggers.forEach((trigger) => {
  trigger.addEventListener('click', () => {
    const { popupTrigger } = trigger.dataset;
    const popupModal = document.querySelector(`[data-popup-modal="${popupTrigger}"]`);
    popupModal.classList.add('is--visible');

    $('body').css({
      height: '100vh',
      'overflow-y': 'hidden',
    });

    popupModal.querySelector('.popup-modal__close').addEventListener('click', () => {
      console.log(popupModal.querySelector('.popup-modal__close'));

      popupModal.classList.remove('is--visible');
      $('body').css({
        height: 'auto',
        'overflow-y': 'auto',
      });
    });
  });
});

///////////////////////////
// Image Popups
///////////////////////////

$('.img-popup').magnificPopup({
  type: 'image',
  closeBtnInside: false,
  closeOnContentClick: false,

  callbacks: {
    open: function () {
      var self = this;
      self.wrap.on('click.pinhandler', 'img', function () {
        self.wrap.toggleClass('mfp-force-scrollbars');
      });
    },
    beforeClose: function () {
      this.wrap.off('click.pinhandler');
      this.wrap.removeClass('mfp-force-scrollbars');
    },
  },

  image: {
    verticalFit: false,
  },
});

//////////////////////
// Intro popups
/////////////////////

var winWidth = window.innerWidth - window.innerWidth * 0.5;
var winHeight = window.innerHeight - window.innerHeight * 0.5;

$('.popup-promo').each(function () {
  let randomTop = getRandomNumber(0, winHeight);
  let randomLeft = getRandomNumber(0, winWidth);

  $(this).css({ left: randomLeft + 'px', top: randomTop + 'px' });

  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    $(this).css({ left: '0px', top: randomTop + 'px' });
    console.log('mobile');
  }

  $(this).draggable({
    start: function () {},

    stop: function () {},
  });
});

// function that returns a random number between a min and max
function getRandomNumber(min, max) {
  return Math.random() * (max - min) + min;
}

function isMobileDevice() {
  return (
    typeof window.orientation !== 'undefined' || navigator.userAgent.indexOf('IEMobile') !== -1
  );
}

$('.btn-close-popup').on('click', function () {
  $(this).parents('div').hide();
});

//////////////////////
// Character speech animation
/////////////////////

setTimeout(function () {
  $('.character-speech').animate(
    {
      bottom: '+=700',
      opacity: '1',
    },
    2000,
    function () {
      setTimeout(function () {
        $('.character-speech').animate(
          {
            bottom: '-=700',
            opacity: '0',
          },
          500
        );
      }, 4000);
    }
  );
}, 6000);
